<template>
  <div>
    <v-data-table v-if="!isLoading" :headers="headers" :items="questions">
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="black" class="mr-3" v-on="on" @click="editQuestion(item)">mdi-pencil</v-icon>
          </template>
          Módosítás
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon size="20" color="black" v-on="on" @click="openDeleteConfirmator(item)">mdi-delete</v-icon>
          </template>
          Törlés
        </v-tooltip>
      </template>
    </v-data-table>

    <v-skeleton-loader type="table" v-else></v-skeleton-loader>
    <ConfirmDialog :confirm-handler="deleteConfirmHandler"></ConfirmDialog>
    <QuestionDialog></QuestionDialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { DELETE_QUESTION } from '@/store/types'

const ConfirmDialog = () => import('@/components/Global/ConfirmDialog.vue')
const QuestionDialog = () => import('@/components/Questions/QuestionDialog.vue')

export default {
  name: 'QuestionsTable',
  components: {
    ConfirmDialog,
    QuestionDialog
  },
  data() {
    return {
      headers: [
        {
          text: '#',
          sortable: false,
          value: 'id'
        },
        {
          text: 'Kérdés címe',
          align: 'start',
          value: 'title'
        },
        {
          text: '',
          value: 'actions',
          align: 'end',
          sortable: false
        }
      ],
      deleteConfirmHandler: {}
    }
  },
  computed: {
    ...mapState({
      questions: state => state.questions.questions
    }),
    isLoading() {
      return this.status === 'loading'
    }
  },
  methods: {
    openDeleteConfirmator(resource) {
      this.deleteConfirmHandler = {
        handler: () => this.deleteResource(resource),
        title: `"${resource.title}" törlése`
      }
      this.$modal.show('confirm-dialog')
    },
    async deleteResource(resource) {
      await this.$store.dispatch(DELETE_QUESTION, resource.id)
      this.$modal.hide('confirm-dialog')
    },
    editQuestion(question) {
      this.$modal.show('question-dialog', {
        question
      })
    }
  }
}
</script>
